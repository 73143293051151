<template>
  <div class="container-fluid" data-bs-theme="dark">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <!-- <li class="breadcrumb-item active">Locations</li> -->
            </ol>
          </div>
          <h4 class="page-title">User Enrollment History</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>

            <div class="table-responsive" v-if="!loading">
              <table class="table table-striped dt-responsive w-100 mb-3" id="yearly-plan-table" v-if="!loading">
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Plan Name</th>
                    <th>Module</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Enroll User</th>
                    <th>Service Year</th>
                    <th>Request By</th>
                    <th>Approved By</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(enrollList, index) in enrollLists" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ enrollList.monthly_plan.name }}</td>
                    <td>{{ enrollList.module.name }}</td>
                    <td>{{ enrollList.monthly_plan.from_date }}</td>
                    <td>{{ enrollList.monthly_plan.to_date }}</td>
                    <td>{{ enrollList.enrolled_by.name }}</td>
                    <td>{{ enrollList.service_year + ' years' }}</td>
                    <td>{{ enrollList.requested_by.name }}</td>
                    <td>{{ enrollList.approved_by.name }}</td>
                    <td>
                      <badgeDanger v-if="enrollList.is_enroll_confirmed == 0" :name="enrollList.is_approved.by" />
                      <div v-if="enrollList.is_enroll_confirmed == 1">
                        <badgeSuccess v-if="enrollList.is_approved.status == true" :name="enrollList.is_approved.by" />
                        <badgeDanger v-if="enrollList.is_approved.status == false" :name="enrollList.is_approved.by" />
                      </div>
                      <badgeWarning v-if="enrollList.is_enroll_confirmed == 2" :name="enrollList.is_approved.by" />
                      <badgeCanceled v-if="enrollList.is_enroll_confirmed == 3" :name="enrollList.is_approved.by" />
                    </td>
                    <td>
                      <router-link class="action-icon" :to="{
                        name: 'enrollment-user-view',
                        params: { id: enrollList.id },
                      }">
                        <button type="button" class="
                              btn btn-xs btn-blue
                              waves-effect waves-light
                              mb-2
                            ">
                          View
                        </button>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Modules
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import badgeSuccess from "../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../components/shared/badgeDanger.vue";
import badgeWarning from "../../components/shared/badgeWarning.vue";
import badgeCanceled from "../../components/shared/badgeCanceled.vue";

export default {
  components: {
    badgeSuccess,
    badgeDanger,
    badgeWarning,
    badgeCanceled,
  },
  data() {
    return {
      enrollLists: [],
      employeeLists: [],
      allMonthlyPlans: [],
      current_budgetyear_id: "",
      current_year_month: "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      enrollList: null,
      status: "waiting",
    };
  },
  methods: {
    // async getAllEmployee() {
    //   this.loading = true;
    //   await axios.get(`${this.baseUrlHRIS}api/employees`).then((response) => {
    //     this.employeeLists = response.data.data;
    //     this.loading = false;
    //   });
    // },
    async getEnrollListByUserId() {
      this.loading = true;
      this.$Progress.start();
      var current_user_id = this.$store.state.auth.user.employee_id;

      await axios
        .get(
          `${this.baseUrl}admin/v1/enrollment-approval?enrollby=${current_user_id}`
        )
        .then((response) => {
          this.enrollLists = response.data.data;
          this.enrollLists.forEach((enroll) => {
            this.employeeLists.forEach((emp) => {
              if(enroll.enrolled_by.employee_id == emp.emp_id) {
                enroll.service_year = emp.service_year;
              }
            })
          })
          this.loading = false;
          this.$Progress.finish();
        });
      $("#yearly-plan-table").DataTable();
    },

    async getAllMonthlyPlans() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/monthly-plans`)
        .then((response) => {
          this.allMonthlyPlans = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        });
    },
    async getCurrentBudgetYear() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/latest-budgetyear`)
        .then((response) => {
          this.current_budgetyear_id = response.data.data.id;
          this.loading = false;
          this.$Progress.finish();
        });
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#yearly-plan-table").DataTable().draw();
    },
    async getEmpList() {
      if(this.$store.getters["odoo/getAllEmployees"].length == 0) {
      this.loading = true;
        setTimeout(() => {
          this.getEmpList();
        }, 3000);
      }else {
        this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
        await this.getEnrollListByUserId();
      }
    }
  },
  async created() {
    this.clearFilter();
    await this.getEmpList();
     this.getAllMonthlyPlans();
    this.getCurrentBudgetYear();
    this.current_year_month = moment().format("YYYY-MM");
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}

</style>